import React from "react"
import { graphql } from "gatsby"
import "../styles/main.scss"
import SEO from "../components/seo"
import { CardColumns } from "../components/CardColumns"
import ArticleCard, { Article } from "../components/ArticleCard"
import CategoryLabel from "../components/CategoryLabel"
import { Skeleton } from "../components/layout/Skeleton"

export default function Home({ data }) {
  const { category, allArticle: articles } = data

  return (
    <>
      <SEO
        title={`Artykuły z sekcji: ${category.title} | Nasze Pierwsze Dziecko`}
      />
      <Skeleton>
        <CategoryLabel title={category.title} />
        <CardColumns>
          {articles.nodes.map((article: Article) => (
            <ArticleCard key={article.id} article={article} />
          ))}
        </CardColumns>
      </Skeleton>
    </>
  )
}

export const query = graphql`
  query($id: String!) {
    category(id: { eq: $id }) {
      title
    }
    allArticle(
      filter: { category: { id: { eq: $id } } }
      sort: { fields: [likes], order: DESC }
    ) {
      nodes {
        ...ArticleNodes
      }
    }
  }
`
